// Bootstrap
$white: #fff;
$gray-100: #f3f3f3;
$gray-200: #e4e4e4;
$gray-300: #b6bcbc;
$gray-400: #a5aeae;
$gray-500: #869292;
$gray-600: #637272;
$gray-700: #4f6161;
$gray-800: #354a4a;
$gray-900: #183030;

$black: #000;
$blue: #00a0df;
$blue-100: #f3fcff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #dc3545;
$orange: #ff6600;
$orange-100: #fff8f3;
$yellow: #ffc107;
$green: #26ba5e;
$teal: #20c997;
$cyan: #82d2f0;

$primary: $orange;

$secondary: $blue;
$success: $green;
$info: $cyan;
$warning: $orange;
$danger: $red;
$light: white;
$dark: $gray-900;

// add custom colors in this map and also check _color-maps.scss for further implementation
$accent: $yellow;
$custom-theme-colors: (
  'accent': $accent,
);

$primary-text-emphasis: shade-color($primary, 40%);
$secondary-text-emphasis: shade-color($secondary, 60%);
$success-text-emphasis: shade-color($success, 60%);
$info-text-emphasis: shade-color($info, 60%);
$warning-text-emphasis: shade-color($warning, 60%);
$danger-text-emphasis: shade-color($danger, 60%);
$light-text-emphasis: $gray-700;
$dark-text-emphasis: $gray-700;

$primary-bg-subtle: #fff8f3;
$secondary-bg-subtle: #f3fcff;
$success-bg-subtle: tint-color($success, 80%);
$info-bg-subtle: tint-color($info, 80%);
$warning-bg-subtle: tint-color($warning, 80%);
$danger-bg-subtle: tint-color($danger, 80%);
$light-bg-subtle: mix($gray-100, $white);
$dark-bg-subtle: $gray-400;

$primary-bg-subtle-dark: shade-color($primary, 75%);

$primary-border-subtle: tint-color($primary, 60%);
$secondary-border-subtle: tint-color($secondary, 60%);
$success-border-subtle: tint-color($success, 60%);
$info-border-subtle: tint-color($info, 60%);
$warning-border-subtle: tint-color($warning, 60%);
$danger-border-subtle: tint-color($danger, 60%);
$light-border-subtle: $gray-200;
$dark-border-subtle: $gray-500;

$body-color: $gray-900;
$body-bg: $white;

$gradient: radial-gradient(104.99% 63.95% at 100% 100%, rgba($white, 0.2) 0%, transparent 100%);
//$color-mode-type: media-query;

// Other
//? Put own extra variables here
