.speech-bubble {
  --speech-bubble-bg: var(--bs-secondary-bg-subtle);
  --speech-bubble-size: 1em;
  --speech-bubble-arrow-offset: 10px;
  background-color: var(--speech-bubble-bg);
  position: relative;
  min-height: 70px;
  padding: spacer(4);
  border-radius: var(--bs-border-radius);
  display: flex;
  align-items: center;
  margin-left: var(--speech-bubble-size);

  @container (max-width: 400px) {
    padding: spacer(3);
  }

  &::after {
    content: '';
    @include abs(var(--speech-bubble-arrow-offset), auto, auto, 0);
    transform: translateX(-100%);
    width: 0;
    height: 0;
    border-top: calc(var(--speech-bubble-size) * 0.75) solid transparent;
    border-right: var(--speech-bubble-size) solid var(--speech-bubble-bg);
    border-bottom: calc(var(--speech-bubble-size) * 0.75) solid transparent;
  }
}
