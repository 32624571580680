body {
  @include tint-css-variables('primary');
  @include tint-css-variables('secondary');

  --bs-primary-100: #{$orange-100};
  --bs-secondary-100: #{$blue-100};

  --bs-border-radius-md: #{$border-radius-md};
  --bs-border-radius-xs: #{$border-radius-xs};

  --container-width-offset: 50px;

  @include media-breakpoint-up(xl) {
    --container-width-offset: 100px;
  }
}

:root {
  --topbar-upper-height: 35px;
  --vh: 9.58px;
  --topbar-height: 89px;
}
