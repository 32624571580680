.button-icon {
  --button-icon-inset: 0;

  width: var(--button-size);
  border-radius: rem(200px);
  justify-content: center;
  padding: 0;
  aspect-ratio: 1/1;

  fstr-icon {
    pointer-events: none;
  }
}

.button-icon--pulse-hover {
  position: relative;

  @media (hover: hover) {
    &:hover {
      &::after,
      &::before {
        @include abs(50%, null, null, 50%);
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
        border-radius: 50%;
        z-index: 1;
        background-color: var(--button-bg);
        content: '';
        width: var(--button-size);
        height: var(--button-size);
      }

      fstr-icon {
        position: relative;
        z-index: 5;
      }

      &::before {
        animation: pulse 2s infinite;
        animation-fill-mode: forwards;
      }

      &::after {
        animation: pulse 2s 0.6s infinite;
        animation-fill-mode: forwards;
      }
    }
  }
}
