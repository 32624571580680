.user-thumb {
  aspect-ratio: 1;
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--bs-secondary-bg-subtle);
  display: flex;
  max-width: var(--user-size);
  width: 100%;

  &.is--logo {
    padding: 10px;

    ::ng-deep img {
      object-fit: contain;
    }
  }
}
