.card {
  --bs-card-aspect-ratio: #{$card-aspect-ratio};
  --bs-card-bg: var(--bs-primary);
  --bs-card-border-width: 0;
  --bs-card-spacer-y: #{spacer(4)};
  --bs-card-spacer-x: #{spacer(4)};
  --bs-card-color: var(--bs-light);
  --bs-card-border-radius: var(--bs-border-radius) var(--bs-border-radius-xl) var(--bs-border-radius) var(--bs-border-radius-xl);

  @include media-breakpoint-down(md) {
    --bs-card-border-radius: var(--bs-border-radius-xs) var(--bs-border-radius-md) var(--bs-border-radius-xs) var(--bs-border-radius-md);
  }

  overflow: hidden;
}

.card--light {
  --bs-card-bg: var(--bs-light);
  --bs-card-color: var(--bs-dark);
}

.card--border-radius-sm {
  --bs-card-border-radius: var(--bs-border-radius) var(--bs-border-radius-md) var(--bs-border-radius) var(--bs-border-radius-md);
}

.card--border-radius-none {
  --bs-card-border-radius: 0;
}

.card-img-overlay {
  position: relative;
  aspect-ratio: var(--bs-card-aspect-ratio, 1/0.75);
}

.card-img-background {
  @include abs(0);
}

test-fstr-image,
fstr-image {
  &.card-img {
    display: block;
    overflow: hidden;
  }

  &.card-img,
  &.card-img-top {
    img {
      @include border-top-radius(var(--#{$prefix}card-inner-border-radius));
    }
  }

  &.card-img,
  &.card-img-bottom {
    img {
      @include border-bottom-radius(var(--#{$prefix}card-inner-border-radius));
    }
  }
}
