@each $name, $color in map-merge($theme-colors, $button-themes) {
  .button--#{$name} {
    --button-bg: #{$color};
    --button-hover-bg: color-mix(in srgb, #{$color} #{100% - $button-hover-shade-amount}, black);
  }
}

// Custom overrides
.button--light,
.button--light-primary,
.button--light-secondary {
  --button-color: black;
}
.button--light-primary {
  --button-hover-bg: color-mix(in srgb, var(--button-bg) 90%, var(--bs-primary));

  &:hover {
    color: color-mix(in srgb, black 90%, var(--bs-primary));
    fill: color-mix(in srgb, black 90%, var(--bs-primary));
  }
}
.button--light-secondary {
  --button-hover-bg: color-mix(in srgb, var(--button-bg) 90%, var(--bs-secondary));

  &:hover {
    color: color-mix(in srgb, black 60%, var(--bs-secondary));
    fill: color-mix(in srgb, black 60%, var(--bs-secondary));
  }
}

$status-light-buttons: 'warning', 'danger', 'info';

@each $name in $status-light-buttons {
  .button--light-#{$name} {
    --button-hover-bg: color-mix(in srgb, var(--button-bg) 90%, var(--bs-#{$name}));
    --button-color: var(--bs-#{$name});

    &:hover {
      color: color-mix(in srgb, black 60%, var(--bs-#{$name}));
      fill: color-mix(in srgb, black 60%, var(--bs-#{$name}));
    }
  }
}

.button.button--primary-100 {
  --button-color: var(--bs-primary);
  --button-hover-bg: color-mix(in srgb, var(--button-bg) 90%, var(--bs-primary));
}

.button.button--secondary-100 {
  --button-color: var(--bs-secondary);
  --button-hover-bg: color-mix(in srgb, var(--button-bg) 90%, var(--bs-secondary));
}

.button.button--dark {
  &:hover {
    color: var(--bs-primary);
  }
}
