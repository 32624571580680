.link-unstyled,
.link-reset {
  text-decoration: none;
  color: currentColor;

  &:focus-visible {
    outline: 2px solid var(--bs-warning);
    outline-offset: 3px;
  }
}

.text-primary-subtle {
  color: var(--bs-primary-bg-subtle) !important;
}

//@for $i from 1 through 9 {
//  .text-primary-#{$i}00 {
//    color: var(--bs-primary-#{$i}00) !important;
//  }
//}

// for 3 items
@for $i from 1 through 5 {
  .max-lines-#{$i} {
    display: -webkit-box;
    -webkit-line-clamp: $i;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

// Fixes cut off text at the bottom (what you will get with normal margin-bottom:0)
.mb-max-lines {
  padding-bottom: 0.1em;
  margin-bottom: -0.1em;
}

.text-truncate {
  overflow: hidden !important;
}

.text-max-width {
  max-width: map-get($text-max-widths, sm);
}

.hyphens-auto {
  hyphens: auto !important;
}

.fw-semi {
  font-weight: 700;
}
@include media-breakpoint-down(md) {
  .text-max-md-center {
    text-align: center !important;
  }
}

.text-bg-warning {
  color: white !important;
}
