.fr-video {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 56.25%;
  margin-bottom: 2ex;
  overflow: hidden;
  border-radius: var(--bs-border-radius-xs) var(--bs-border-radius-md);

  @include media-breakpoint-up(sm) {
    border-radius: var(--bs-border-radius-sm) calc(var(--bs-border-radius-xl));
  }

  @include media-breakpoint-up(lg) {
    --outset: 100px;
    margin-left: calc(var(--outset) * -1);
    margin-right: calc(var(--outset) * -1);
    width: calc(100% + var(--outset) * 2);
    max-width: none;
    padding-top: calc(56.25% + var(--outset) * 2);
  }
  @include media-breakpoint-up(xxl) {
    --outset: 150px;
  }

  iframe {
    @include abs(0, 0, 0, 0);
    @include size(100%);
  }
}

iframe,
object,
embed {
  width: 100%;
  height: auto;
}
