.button.button-link {
  --button-icon-inset: 0;
  --button-icon-size: 1.5em;

  background: transparent;
  color: var(--button-bg);
  padding: 0;
  border-radius: 0;

  fstr-icon {
    color: inherit;
  }

  @media (hover: hover) {
    &:hover {
      background: transparent;
      color: var(--button-hover-bg);
    }
  }
}
