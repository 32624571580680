.pt-topbar {
  padding-top: calc(var(--topbar-height) + var(--topbar-upper-height) + 3rem);
}
.mt-topbar {
  margin-top: calc(var(--topbar-height) + var(--topbar-upper-height) + 3rem);
}

.main-nav--active {
  overflow: hidden;
  height: 100vh;
}

.header--video-playing .topbar {
  opacity: 0;
  transform: translate3d(0, -50%, 0);

  &:hover {
    opacity: 1;
    transform: translate3d(0, 0%, 0);
  }
}
